import type { WritableAtom, useStore } from 'jotai';
import { useHydrateAtoms } from 'jotai/react/utils';
import type { INTERNAL_InferAtomTuples } from 'jotai/react/utils/useHydrateAtoms';
import type { PropsWithChildren } from 'react';

type HydrateAtomOptions = Parameters<typeof useStore>[0] & {
  dangerouslyForceHydrate?: boolean;
};
// biome-ignore lint/suspicious/noExplicitAny: generic
type AnyWritableAtom = WritableAtom<unknown, any[], any>;
type InferAtomTuples<T> = INTERNAL_InferAtomTuples<T>;

export function HydrateAtoms<T extends (readonly [AnyWritableAtom, unknown])[]>(
  props: Readonly<PropsWithChildren<{ initialValues: InferAtomTuples<T>; options?: HydrateAtomOptions }>>
): PropsWithChildren['children'];
export function HydrateAtoms<T extends Map<AnyWritableAtom, unknown>>(
  props: Readonly<PropsWithChildren<{ initialValues: T; options?: HydrateAtomOptions }>>
): PropsWithChildren['children'];
export function HydrateAtoms<T extends Iterable<readonly [AnyWritableAtom, unknown]>>({
  initialValues,
  options,
  children,
}: Readonly<
  PropsWithChildren<{
    initialValues: InferAtomTuples<T>;
    options?: HydrateAtomOptions;
  }>
>): PropsWithChildren['children'] {
  // initialising on state with prop on render here
  useHydrateAtoms<T>(initialValues, options);
  return children;
}
