import { zodResolver } from '@hookform/resolvers/zod';
import { Provider as JotaiProvider } from 'jotai';
import type { ComponentProps, PropsWithChildren } from 'react';
import { useMemo } from 'react';
import type { DefaultValues, FieldValues, UseFormReturn } from 'react-hook-form';
import { Form as RemixForm } from 'react-router';
import { RemixFormProvider } from 'remix-hook-form';
import type * as z from 'zod';

import { defaultValueAtom, schemaAtom } from '~/components/form/form-atom';
import { useRemixForm } from '~/components/form/utilities';
import { HydrateAtoms } from '~/components/hydrate-atoms';
import { cn } from '~/libs/utils';
import { getDefaultFormValues } from '~/utils/zod';

type FormSettings<TFieldValues extends FieldValues = FieldValues> = Omit<UseFormReturn<TFieldValues>, 'resolver'>;

type BaseRemixFormProps = ComponentProps<typeof RemixForm>;
type RemixFormProps = Omit<BaseRemixFormProps, 'onSubmit' | 'defaultValue' | 'encType'>;
export type FormProps<Schema extends z.Schema = z.Schema, TFieldValues extends FieldValues = z.infer<Schema>> = {
  schema: Schema;
  formSettings?: FormSettings<TFieldValues>;
  defaultValue?: DefaultValues<TFieldValues>;
  className?: string;
  encType?: BaseRemixFormProps['encType'];
} & RemixFormProps;

export const Form = <Schema extends z.Schema, TFieldValues extends FieldValues = z.infer<Schema>>({
  schema,
  formSettings,
  children,
  defaultValue,
  className,
  method,
  encType,
  ...props
}: PropsWithChildren<FormProps<Schema, TFieldValues>>) => {
  const resolver = useMemo(() => zodResolver(schema), [schema]);

  const defaultValues = useMemo<DefaultValues<TFieldValues> | undefined>(
    () => getDefaultFormValues<Schema, TFieldValues>(schema, (defaultValue ? defaultValue : {}) as TFieldValues),
    [defaultValue, schema]
  );

  const form = useRemixForm<TFieldValues>({
    resolver,
    defaultValues,
    stringifyAllValues: false,
    // progressive: true,
    submitConfig: {
      ...props,
    },
    ...formSettings,
  });
  const { handleSubmit } = form;

  return (
    <JotaiProvider>
      <HydrateAtoms
        initialValues={[
          [schemaAtom, schema],
          [defaultValueAtom, defaultValues],
        ]}
      >
        <RemixFormProvider {...form}>
          <RemixForm
            role='form'
            data-testid='form'
            data-testid-is-submitting={form.formState.isSubmitting}
            data-testid-is-submitted={form.formState.isSubmitted}
            data-testid-is-valid={form.formState.isValid}
            data-testid-is-dirty={form.formState.isDirty}
            data-testid-errors={JSON.stringify(form.formState.errors)}
            aria-invalid={!form.formState.isValid}
            aria-busy={form.formState.isSubmitting}
            className={cn('space-y-4', className)}
            onSubmit={handleSubmit}
            method={method ?? 'POST'}
            encType={encType as BaseRemixFormProps['encType']}
            {...props}
          >
            {children}
          </RemixForm>
        </RemixFormProvider>
      </HydrateAtoms>
    </JotaiProvider>
  );
};
