import type { FormEvent } from 'react';
import { useMemo, useState } from 'react';
import type {
  DefaultValues,
  FieldErrors,
  FieldValues,
  FormState,
  KeepStateOptions,
  Path,
  RegisterOptions,
  SubmitErrorHandler,
} from 'react-hook-form';
import { useForm } from 'react-hook-form';
import type { FormEncType, FormMethod } from 'react-router';
import { useActionData, useNavigation, useSubmit } from 'react-router';
import type { UseRemixFormOptions } from 'remix-hook-form';

export const useRemixForm = <T extends FieldValues>({
  submitHandlers,
  submitConfig,
  submitData,
  fetcher,
  stringifyAllValues = true,
  ...formProps
}: UseRemixFormOptions<T>) => {
  const [isSubmittedSuccessfully, setIsSubmittedSuccessfully] = useState(false);
  const actionSubmit = useSubmit();
  const actionData = useActionData();
  const submit = fetcher?.submit ?? actionSubmit;
  const data: { defaultValues?: DefaultValues<T>; errors?: FieldErrors<T> } = (fetcher?.data ?? actionData) as {
    defaultValues?: DefaultValues<T>;
    errors?: FieldErrors<T>;
  };
  const methods = useForm<T>({ ...formProps, errors: data?.errors });
  const navigation = useNavigation();
  // Either it's submitted to an action or submitted to a fetcher (or neither)
  // biome-ignore lint/correctness/useExhaustiveDependencies: This is a false positive
  const isSubmittingForm = useMemo(
    () =>
      (navigation.state !== 'idle' && navigation.formData !== undefined) ||
      (fetcher && fetcher.state !== 'idle' && fetcher.formData !== undefined),
    [navigation.state, navigation.formData, fetcher?.state, fetcher?.formData]
  );

  // Submits the data to the server when form is valid
  const onSubmit = useMemo(
    () => (data: T, _?: never, formEncType?: FormEncType, formMethod?: FormMethod) => {
      setIsSubmittedSuccessfully(true);
      const encType = submitConfig?.encType ?? formEncType;
      const method = submitConfig?.method ?? formMethod ?? 'post';
      const submitPayload = { ...data, ...submitData };
      const formData =
        encType === 'application/json' ? submitPayload : createFormData(submitPayload, stringifyAllValues);
      submit(formData, {
        ...submitConfig,
        method,
        encType,
      });
    },
    [submit, submitConfig, submitData, stringifyAllValues]
  );

  const onInvalid = useMemo<SubmitErrorHandler<T>>(() => () => {}, []);

  // React-hook-form uses lazy property getters to avoid re-rendering when properties
  // that aren't being used change. Using getters here preservers that lazy behavior.
  const formState: FormState<T> = useMemo(
    () => ({
      get defaultValues() {
        return methods.formState.defaultValues;
      },
      get dirtyFields() {
        return methods.formState.dirtyFields;
      },
      get disabled() {
        return methods.formState.disabled;
      },
      get errors() {
        return methods.formState.errors;
      },
      get isDirty() {
        return methods.formState.isDirty;
      },
      get isLoading() {
        return methods.formState.isLoading;
      },
      get isSubmitSuccessful() {
        return isSubmittedSuccessfully || methods.formState.isSubmitSuccessful;
      },
      get isSubmitted() {
        return methods.formState.isSubmitted;
      },
      get isSubmitting() {
        return isSubmittingForm || methods.formState.isSubmitting;
      },
      get isValid() {
        return methods.formState.isValid;
      },
      get isValidating() {
        return methods.formState.isValidating;
      },
      get submitCount() {
        return methods.formState.submitCount;
      },
      get touchedFields() {
        return methods.formState.touchedFields;
      },
      get validatingFields() {
        return methods.formState.validatingFields;
      },
    }),
    [methods.formState, isSubmittedSuccessfully, isSubmittingForm]
  );
  const reset = useMemo(
    () => (values?: T | DefaultValues<T> | undefined, options?: KeepStateOptions) => {
      setIsSubmittedSuccessfully(false);
      methods.reset(values, options);
    },
    [methods.reset]
  );

  const register = useMemo(
    () =>
      (
        name: Path<T>,
        options?: RegisterOptions<T> & {
          disableProgressiveEnhancement?: boolean;
        }
      ) => ({
        ...methods.register(name, options),
        ...(!options?.disableProgressiveEnhancement && {
          defaultValue: data?.defaultValues?.[name] ?? '',
        }),
      }),
    [methods.register, data?.defaultValues]
  );

  const handleSubmit = useMemo(
    () => (e: FormEvent<HTMLFormElement>) => {
      const encType = e?.currentTarget?.enctype as FormEncType | undefined;
      const method = e?.currentTarget?.method as FormMethod | undefined;
      const onValidHandler = submitHandlers?.onValid ?? onSubmit;
      const onInvalidHandler = submitHandlers?.onInvalid ?? onInvalid;

      return methods.handleSubmit((data, e) => onValidHandler(data, e as never, encType, method), onInvalidHandler)(e);
    },
    [methods.handleSubmit, submitHandlers, onSubmit, onInvalid]
  );

  return useMemo(
    () => ({
      ...methods,
      handleSubmit,
      reset,
      register,
      formState,
    }),
    [methods, handleSubmit, reset, register, formState]
  );
};

/**
 Creates a new instance of FormData with the specified data and key.
 @template T - The type of the data parameter. It can be any type of FieldValues.
 @param data - The data to be added to the FormData. It can be either an object of type FieldValues.
 @param stringifyAll - Should the form data be stringified or not (default: true) eg: {a: '"string"', b: "1"} vs {a: "string", b: "1"}
 @returns {FormData} - The FormData object with the data added to it.
 */
const createFormData = <T extends FieldValues>(data: T | undefined, stringifyAll = false): FormData => {
  const formData = new FormData();
  if (!data) {
    return formData;
  }
  for (const [key, value] of Object.entries<unknown>(data)) {
    if (value instanceof FileList) {
      for (const element of value) {
        if (element) {
          formData.append(key, element);
        }
      }
    } else if (!(value instanceof FileList) && (value instanceof File || value instanceof Blob)) {
      formData.append(key, value);
    } else if (stringifyAll) {
      formData.append(key, JSON.stringify(value));
    } else if (value) {
      if (typeof value === 'string') {
        formData.append(key, value);
      } else if (value instanceof Date) {
        formData.append(key, value.toISOString());
      } else if (value !== undefined && value !== null) {
        formData.append(key, JSON.stringify(value));
      }
    }
  }

  return formData;
};
